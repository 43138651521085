<template>
	<div class="steppers">
		<v-stepper ref="stepper" :steps="steps" v-model="step"></v-stepper>

		<section class="body-steps">
			<div v-if="step == 1" class="container-calendar">
				<h2>Visita a cliente</h2>
				<div>Programar la fecha en la que se llevará acabo la visita al inmueble</div>

				<Calendar :options="calendar_config" :eventos="eventos" @nEventos="nuevos_eventos"/>

			</div>
			<div v-if="step == 2" class="">
				<h2>Avalúo</h2>
				<div>Sube aquí tu documento, una vez completado el paso haz click en "Enviar".</div>
				<br>

				<div class="form-group">
					<input type="file" class="form-control-file" id="documento_a_subir">
				</div>

				<div class="row">
					<div class="col-sm-12 d-flex justify-content-end">
						<div class="col-sm-3">
							<button type="button" @click="subir_documento" class="btn principal-btn">Enviar</button>
						</div>
					</div>
				</div>
			</div>
			<div v-if="step == 3" class="text-center">
				<h2>La documentación fue recibida con éxito.</h2>
				<div class="row">
					<div class="col-sm-12 d-flex justify-content-center">
						<div class="col-sm-3">
							<button type="button" class="btn principal-btn" @click="$router.push({name: 'formalizacion_valuadores'})">Finalizar</button>
						</div>
					</div>
				</div>
			</div>
		</section>
		<div class="block"></div>
	</div>
</template>

<script>
	import { VStepper } from 'vue-stepper-component'

	import api from '@/apps/formalizacion/api/v1/formalizacion'
	import Calendar from '@/components/Calendar'

	import appconfig from '@/apps/formalizacion/config'

	export default {
		components: {
			VStepper, Calendar
		},
		data: () => ({
			steps: 3,
			step: 1,
			eventos: [],
			eventos_nuevos: [],
			calendar_config: {
				agregar_eventos: true
				,agregar_invitados: false
				,title: null
				,descript: null
				,location: null
				,invitados: []
			}
			,config: {}
		})
		,props: {
			solicitud: {
				type: Object
				,default: () => ({
					documentacion_basica: null,
					evaluacion: null,
					documentacion_completa: null,
					firma: null
				})
			}
		}
		,mounted: function() {
			this.config = appconfig[this.$helper.getEnv()];
		}
		,methods: {
			visitar_pasos: function(step) {
				let steps = document.querySelectorAll('.v-step');

				this.step = step;
				step = step-1;

				let parent = this;
				setTimeout(function() {
					for(let i=0; i<step; i++) {
						parent.$log.info('step: ', steps[i]);
						steps[i].classList.add('is-visited');  

					  // steps[i].setAttribute('data-prueba','prueba');
					}
				},200);
			}
			,obtenemos_eventos: async function() {
				try {
					this.eventos = (await api.mis_eventos()).data;

					for(let i=0; i<this.eventos.length; i++) {
						this.eventos[i].attendees = this.eventos[i].invitados;
						delete this.eventos[i].invitados;
					}

					this.$log.info('eventosAgrear: ', this.eventos);
				}catch(e) {
					this.$helper.showAxiosError(e,'Error');
				}
			}
			,subir_documento: async function() {
				let objdoc = document.getElementById('documento_a_subir');

				if (objdoc.files.length > 0) {
					try {
						let formData = new FormData();
						formData.append('documento', objdoc.files[0]);
						formData.append('descripcion','Evaluación de la solicitud '+this.solicitud.solicitud);
						formData.append('origen','evaluador');

						if (objdoc.files[0].size < 64000000) {
							let response = (await api.subir_documento(this.solicitud.solicitud, formData)).data;
							this.$log.info('response', response);

							if (response.success) {
								await api.actualizar_fase(this.solicitud.solicitud, 'evaluacion');
								this.$refs.stepper.next();
							}else
								this.$helper.showMessage('Error','Error al subir el documento: '+response.message, 'error', 'alert');
						}else {
							this.$helper.showMessage('Error','Recuerda que el documento no puede pesar más de 64MB', 'error', 'alert');
						}
					}catch(e) {
						this.$log.info('Error', e);
						this.$helper.showAxiosError(e, 'Error');
					}
				}else {
					this.$helper.showMessage('Error','Tienes que seleccionar un documento a subir', 'error', 'alert');
				}
			}
			,nuevos_eventos: async function(eventos) {
				this.$log.info('eventosNuevos',eventos);
				let newEvents = 0;
				let errores = [];


				for(let i=0; i<eventos.length; i++) {
					if (typeof(eventos[i].id) == 'undefined')
						try {
							eventos[i].event = 'fecha_cita';
							await api.crear_cita(this.solicitud.solicitud, eventos[i]);

							newEvents++;
						}catch(e) {
							let tmpError = '<ul>';

							let keys = Object.keys(e.response.data.error);

							keys.forEach(key => {
								e.response.data.error[key].forEach(err => {
									tmpError += '<li>'+err+'</li>';
								});
							})
							tmpError += '</ul>';

							errores.push({evento: eventos[i].title, error: tmpError, hash: eventos[i].hash});
						}
				}

				if (errores.length == 0) {
					if (newEvents > 0) {
						this.$helper.showMessage('Evento creado','El evento se a creado correctamente, se a enviado los correos de notificación a los invitados designados', 'success', 'notifications');

						this.$refs.stepper.next();

						this.calendar_config.agregar_eventos = false;
					}
				}else {
					let mssg = 'Se encontraron los siguientes errores: <ul>';

					let hashError = [];

					for(let i=0; i<errores.length; i++) {
						hashError.push(errores[i].hash);
						mssg += '<li>'+errores[i].evento+': '+errores[i].error+'</li>';
					}
					mssg += '</ul>';

					let tmpEvents = [];

					eventos.forEach(evento => {
						if (evento.hash && hashError.indexOf(evento.hash) == -1)
							tmpEvents.push(evento);
					});

					this.eventos = tmpEvents;
					this.$log.info('eventos', this.eventos);

					this.$helper.showMessage('Error', mssg, 'error', 'alert');
				}
			}
			,checkStep: function(step) {
				if (this.solicitud) {
					let steps = [];
					this.solicitud.sla.forEach(sla => {
						steps.push(sla.tipo);
					});

					return steps.indexOf(step) != -1;
				}else {
					return false;
				}
			}
		}
		,watch: {
			solicitud: {
				handler: function(val, oldVal) {
					this.obtenemos_eventos();

					if (this.solicitud && this.solicitud.sla && this.solicitud.sla.length > 0) {
						let location = '';

						if (this.solicitud.calle)
							location += this.solicitud.calle+', ';

						if (this.solicitud.colonia)
							if (location == '')
								location += this.solicitud.colonia+', ';
							else
								location += ' '+this.solicitud.colonia+', ';

						if (this.solicitud.estado)
							location += ' '+this.solicitud.estado+', ';

						if (this.solicitud.municipio)
							location += ' '+this.solicitud.municipio+', ';

						if (this.solicitud.cp)
							location += ' CP '+this.solicitud.cp;

						this.calendar_config.title = 'Solicitud '+this.solicitud.solicitud+': Visita de evaluación';
						this.calendar_config.descript = 'Evento para la visita por parte del valuador para revisión de inmueble';
						this.calendar_config.location = location;

						let emailValuador = this.$auth.getUser().email;

						if (!emailValuador)
							this.$auth.getUser().contactos.forEach(contacto => {
								if (!emailValuador && contacto.tipo == 'email')
									emailValuador = contacto.valor;
							});

						this.calendar_config.invitados = [
							{
								name: this.$auth.getUser().nombre
								,email: emailValuador
							}
							,{
								name: this.solicitud.nombre
								,email: this.solicitud.email
							}
							,...this.config.attendees
						];
						this.$log.info('invitados', this.calendar_config.invitados);

						if (this.checkStep('evaluacion')) {
							this.$log.info('Evento evaluacion existe');
							this.calendar_config.agregar_eventos = false;
							this.visitar_pasos(1);
						}else if (this.checkStep('fecha_cita')) {
							this.$log.info('Evento fecha_cita existe');
							this.visitar_pasos(2);
						}
					}
				}
				,deep: true
			}
			,step: function() {
				this.$log.info('step', this.step);
			}
		}
	}
</script>

<style lang="scss">
	.block {
		width: 100%;
		height: 100px;
		position: absolute;
		top: 42px;
		left: 39px;
		z-index:100;
	}
	.is-visited, 
	.is-active {
		label {
			span.index {
				background-color: #3CA63E !important;
				border: 1px solid #3CA63E !important;
				color: #fff !important;
				width: 5rem;
				height: 5rem;
				font-size: 2rem !important;
			}
		}
	}

	.steppers {
		padding: 3rem;
		margin: auto 10rem;

		.body-steps {
			padding: 1rem;

			.text-center {
				h2 {
					padding-bottom: 2rem;
				}
			}
		}
		label {
			span.index {
				width: 5rem;
				height: 5rem;
				font-size: 1.5rem;
			}
		}
	}
	.container-calendar {
		position: relative;

		.button-calendar {
			width: 200px;
			position: absolute;
			top: 66px;
			right: 1px;
		}
	}
</style>